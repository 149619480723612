.slider-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #00000033;
  background-color: #d9d9d9;
}

.slider-row {
  margin-top: 7.5px;
  display: flex;
  flex-direction: row;

}

.input-wrapper {
  display: flex;
  flex-direction: column;
}

.slider-part {
  display: flex;
  align-items: center;
}

.inner-wrapper {
  padding: 2px 2px 2px 2px;
  user-select: none;
  outline: none;
  border: none;
}

.custom-dimension-text {
  font-size: 13.5px;
  color: #555;
  margin-bottom: epx;
  margin-top: 3px;
  margin-left: 5px;

}
.custom-text-wrapper{
  align-self: flex-start;
  text-align: left;
  margin-top: 4px;
  margin-bottom: 2px;
}

.input-container {
  display: flex;
  width: 160px;
}

.fake-input {
  background-color: #f5f4f4;
  color: #525251;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
  width: 110px;
  user-select: none;
  outline: none;
  border: none;
  /*  border-right: 2px solid #00000033;*/
}

.unit {
  font-weight: 400;
  font-size: 1.3rem;
  pointer-events: none;
  color: #525251;
  text-align: left;
}

.dimension-input {
  border: none;
  background: transparent;
  color: #525251;
  font-weight: 700;
  font-size: 1rem;
  outline: none;
  width: 160px;
}

.range-warning {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.info-icon2 {
  position: absolute;
  top: 40%;
  width: 15px;
  height: 15px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 12px;
  font-size: 10.5px;
  cursor: pointer;
  border: 1.5px solid black;
  box-sizing: border-box;
  padding-top: 0.75px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.bold-text {
  font-weight: bold;
}

@media (orientation: landscape) and (max-height: 480px) {
  .slider-row {
    margin-left: 25px;
  }
  .dimension-input {
    width: 110px;
  }
  .fake-input {
    width: 90px;
  }
  .info-icon2 {
    left: 1%;
  }
}

@media (orientation: landscape) and (max-height: 412px) {
  .dimension-input {
    width: 115px;
  }
  .fake-input {
    width: 90px;
  }
}


@media (orientation: landscape) and (max-height: 392px) {
  .dimension-input {
    width: 110px;
  }
 
}

@media (orientation: landscape) and (max-height: 360px) {
  .slider-row {
    align-self: center;
  }

}


@media screen and (min-resolution: 144dpi) {
  .slider-container {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #00000033;
  }

  .custom-dimension-text {
    font-size: 11px;
    margin-top: 1px;
  }
  .fake-input {
    font-size: 1rem;
  }

  .unit {
    font-size: 1rem;
  }

  .dimension-input {
    font-size: 0.8rem;
  }

  .range-warning {
    font-size: 1rem;
    margin-top: 3px;
  }

 

  .info-icon2 {
    width: 12px;
    height: 12px;
    line-height: 10px;
    font-size: 9px;
    border: 1px solid black;
    padding-top: 1px;
  }
}
