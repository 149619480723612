.config-block {
    position: fixed;
    top: 0;
    right: 0;
    height: calc(100% - 80px);
    width: 530px;
    padding: 2vh 20px 2vh 0;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  
  }
  
  @media (orientation: landscape) and (max-height: 480px) {
    .config-block {
      height: calc(100% - 60px);
      width: 50%;
      padding: 2vh 10px 2vh 20px;
    }
  }
  