.rhombus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 80vh;
   overflow-y: auto;
}

.rhombus-row {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px; /* Space between rhombuses */
}
