.mobile-menu {
  position: fixed;
  bottom: 4.4vh;
  right: 2vh;
  width: calc(50% - 30px);
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: rgba(31, 31, 31, 255);
  color: white;
  visibility: hidden;
  z-index: 999;
}

.menu-button {
  background-color: #f1f1f1;
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

@media (orientation: landscape) and (max-height: 480px) {
  .mobile-menu {
    visibility: visible;
  }
}

