.colorgrid {
  gap: 1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 8px;
}

@media (orientation: landscape) and (max-height: 480px) {
  .colorgrid {
    gap: 6px;
    margin-bottom: 0px;
  }
}
