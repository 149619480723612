@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
.color-choice-rhombus-wrapper {
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  transition: all 0.1s ease-in-out;
  position: relative;
}

.color-choice-rhombus {
  position: relative;
  width: 450px;
  height: 42px;
}

.color-block {
  width: 100%;
  height: 100%;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translateY(-50%);
  font-size: 16px;
  padding: 2px 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.bold {
  font-weight: bold;
}

.regular {
  font-weight: 400;
}

.color-choice-rhombus-wrapper.selected {
  outline: 3px solid rgb(130, 194, 37);
}

@media (orientation: landscape) and (max-height: 480px) {
  .color-choice-rhombus {
    width: 350px;
    height: 34px;
  }
  .color-choice-rhombus-wrapper {
    margin-bottom: 2px;
  }
  .hover-text{
    font-size: 14px;
  }
}

@media (orientation: landscape) and (max-height: 392px) {
  .color-choice-rhombus {
    width: 320px;
    height: 32px;
  }
  .hover-text{
    font-size: 13.5px;
  }
}

@media (orientation: landscape) and (max-height: 360px) {
  .color-choice-rhombus {
    width: 290px;
    height: 32px;
  }
  .hover-text{
    font-size: 13px;
  }
}
