.model-choice-rhombus-wrapper {
  display: flex;
  cursor: pointer;
  justify-content: center;
  transition: 0.3s ease-in-out;
  width: 100%;
  margin: 0;
}

.model-choice-rhombus {
  background: linear-gradient(135deg, #444, #777);
  box-shadow: 0 1.2px 1.5px rgba(0, 0, 0, 0.1); /* Smaller, less noticeable shadow */
  width: 100%;
  height: 215px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.model-choice-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  filter: brightness(70%) hue-rotate(20deg) saturate(1.2); /* Enhance colors */
  transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.model-choice-image-selected {
  filter: brightness(100%) hue-rotate(20deg) saturate(1.5) !important;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); /* Smaller, more subtle shadow */
  transform: scale(1.02); /* Slight zoom to indicate selection */
}

.model-choice-rhombus:hover .model-choice-image {
  transform: scale(1.1) translateY(-2px);
}

.model-choice-rhombus::after {
  content: attr(data-hover-text);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  font-size: 18px;
  font-weight: bold;
  width: auto;
  max-width: 80%;
  padding: 5px 10px;
  background-color: rgba(200, 200, 200, 1);
  color: black;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: brightness(70%); /* Match image brightness */
}

.model-choice-rhombus-wrapper.selected .model-choice-rhombus::after {
  filter: brightness(100%);
}

.info-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid black;
  padding-top: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Smaller shadow */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.model-choice-rhombus-wrapper:hover .info-icon {
  opacity: 1;
}

@media screen and (min-resolution: 144dpi) {
  .model-choice-rhombus {
    height: 185px;
  }
  .model-choice-rhombus::after {
    max-width: 90%;
  }
}

@media (orientation: landscape) and (max-height: 480px) {
  .model-choice-rhombus {
    height: 185px;
  }
  .model-choice-rhombus::after {
    width: 85%;
  }
}
@media (orientation: landscape) and (max-height: 392px) {
  .model-choice-rhombus {
    height: 185px;
  }
  .model-choice-rhombus::after {
    width: 85%;
  }
}


@media (orientation: landscape) and (max-height: 360px) {
  .model-choice-rhombus {
    height: 185px;
  }
  .model-choice-rhombus::after {
    width: 85%;
  }
}

