.panel-colorgrid {
    gap: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;


}

.panel-colorgrid-special {
    gap: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}


@media (orientation: landscape) and (max-height: 480px) {
    .panel-colorgrid {}

    .panel-colorgrid-special {}
}

@media (orientation: landscape) and (max-height: 392px) {
    .panel-colorgrid {}
}

@media (orientation: landscape) and (max-height: 360px) {
    .panel-colorgrid {}

    .panel-colorgrid-special {}
}