.RedirectHomePopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 998;
  pointer-events: auto;
  background-color: rgba(43, 43, 43, 0.2);
}

.RedirectHomePopup-content {
  position: relative;
  z-index: 999;
  flex-direction: column;
  color: #fff;
  padding: 36px 144px;
  pointer-events: auto;
  max-width: 90vw;
  background-color: rgb(251, 251, 251);
  overflow-y: auto;
}

.RedirectHomePopup-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.RedirectHomePopup-finalization-summary-container {
  display: flex;
  flex-direction: column;
}

@media (orientation: landscape) and (max-height: 480px) {
  .rdhpop {
  }
}

@media (orientation: portrait) {
  .RedirectHomePopup-overlay {
    display: none;
  }
}

input:-internal-autofill-selected {
  background-color: transparent;
  color: white;
}
