.dimensionslider {
  display: flex;
  margin-top: 8px;
  margin-bottom: 3px;
  width: 320px

}


@media (orientation: landscape) and (max-height: 480px) {
  .dimensionslider {
    width: 255px
  }
}

