.StayButton {
  background-color: #82c225;
  color: white;
  padding: 6px 30px;
  outline: none;
  transition: background-color 0.2s;
  border: 3px solid #82c225;
}

.StayButton:focus {
  outline: none;
}

.StayButton:hover {
  background-color: #fff;
  color: #82c225;
  border: 3px solid #82c225;
}
