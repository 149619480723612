.colorwrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.color-choice-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  margin-bottom: 24px;
}

.color-choice-row {
  display: flex;
  justify-content: flex-start; 
  align-items: center;
}
.grid-wrapper{
  margin-top: 5px;
}

.header-text {
  color: #000000;
  padding-bottom: 8px;
  font-weight: bold;
  align-self: start;

}

@media (orientation: landscape) and (max-height: 480px) {
  .header-text {
    font-size: 0.95rem;

  }
  .grid-wrapper{
    margin-top: 10px;
  }
}
@media (orientation: landscape) and (max-height: 428px) {
  .header-text {
    font-size: 0.95rem;

  }
  .grid-wrapper{
    margin-top: 12px;
  }
}


@media (orientation: landscape) and (max-height: 392px) {
  .header-text {
    font-size: 0.95rem;

  }
  .grid-wrapper{
    margin-top: 12px;
  }
}

@media (orientation: landscape) and (max-height: 360px) {
  .header-text {
    font-size: 0.95rem;

  }
  .grid-wrapper{
    margin-top: 12px;
  }
}