.mobile-redirect-button {
    background: none; /* Ensure no background behind the icon */
    transition: background-color 0.2s, border-color 0.2s;
    cursor: pointer;
  }
  
  .mobile-redirect-icon {
    margin-top: 7px;
    width: 35px; /* Adjust width as needed */
    height: 35px; /* Adjust height as needed */
  }

  /*
  
  @media (orientation: landscape) and (max-height: 480px) {
    .mobile-redirect-button {
      display: none;
    }
  }
  
  @media (orientation: landscape) and (max-height: 390px) {
    .mobile-redirect-button {
      display: none;
    }
  }
  
  @media (orientation: landscape) and (max-height: 375px) {
    .mobile-redirect-button {
      display: none;
    }
  }
  
  @media (orientation: portrait) {
    .mobile-redirect-button {
      display: none;
    }
  }
  */